/*********
 * Fonts *
 *********/
$primaryFont: 'Segoe UI', 'Roboto', sans-serif;

/**********
 * Colors *
 **********/
$primaryColor: #36acb7;
$highlightColor: #3299f7;
$formFieldInvalid: #e44a36;

// Buttons
$selectableButtonColor: #3299f7;

// Fonts
$blackFontColor: #3E3F42;
$greyFontColor: #9ea0a5;
$placeholderColor: #95989e;

// Backgrounds
$lightBgColor: #F4F7FB;

// Borders
$borderColor: #dadee7;
$borderHoverColor: #95989e;
$lightBorderColor: #ebeff7;

// Match Colors
$highGreen: rgb(92, 201, 118);
$mediumOrange: rgb(245, 171, 66);
$lowRed: rgb(225, 70, 91);

/*****************
 * Miscellaneous *
 *****************/
$mobileBreak: "max-width: 800px";
