@import "vars";

html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: $primaryFont; }

body {
  min-height: 95vh;
  position: relative;
  margin: 0;
  // padding-bottom: 8rem
  background: #FBFBFD;
  color: #212121; }

button {
	cursor: pointer; }

input {
	font-family: $primaryFont; }

input[type='checkbox'] {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeSpeed;
	height: 16px;
	width: 16px;
	margin: 0;
	margin-right: 10px;
	display: block;
	float: left;
	position: relative;
	cursor: pointer; }

input[type='checkbox']:after {
	content: "";
	vertical-align: middle;
	text-align: center;
	line-height: 13px;
	position: absolute;
	cursor: pointer;
	height: 16px;
	width: 16px;
	left: 0;
	top: 0;
	font-size: 14px;
	border-radius: 3px;
	border: 2px solid #d8d8d8;
	background: white; }

input[type='checkbox'][readonly]:after {
	content: "";
	vertical-align: middle;
	text-align: center;
	line-height: 13px;
	position: absolute;
	cursor: pointer;
	height: 16px;
	width: 16px;
	left: 0;
	top: 0;
	font-size: 14px;
	border-radius: 3px;
	border: 2px solid rgba(108,117,125,.9);
	background: rgba(108,117,125,.9); }

input[type='checkbox']:checked:after {
	background: #48de59;
	border: 2px solid #48de59;
	content: '\2714';
	color: #fff;
	font-size: 18px; }

input[type='checkbox'][readonly]:checked:after {
	background: rgba(108,117,125,.9);
	border: 2px solid rgba(108,117,125,.9);
	content: '\2714';
	color: #fff;
	font-size: 18px; }

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out; }

.ReactModal__Overlay--after-open {
    opacity: 1; }

.ReactModal__Overlay--before-close {
    opacity: 0; }
