@import "../../../../styles/vars";

.container {
  height: 100vh;
  width: 80%;
  background: $lightBgColor;
  display: flex;
  align-items: center;
  justify-content: center; }

.welcomeCard {
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  min-width: 262px;
  background: white;
  box-sizing: border-box;
  padding: 30px 30px;
  box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.12);
  z-index: 3;

  @media ($mobileBreak) {
    width: 80%; } }

.backgroundImg {
  position: absolute;
  left: -10px;
  top: -30px;
  opacity: 0.25;
  padding: 80px; }

.right {
  width: 100%;
  display: flex;
  flex-direction: row-reverse; }

.exploreButton {
  padding: 7px 16px;
  font-size: 1.3em;
  border: none;
  color: white;
  border-radius: 8px;
  background: $highlightColor;

  &:hover, &:focus {
    background: darken($highlightColor, 15%);
    outline: none; } }
